import './scss/style.scss';

// jQuery
import $ from 'jquery'

window.jQuery = window.$ = $

// Bootstrap JS
import 'bootstrap';
import 'jquery.cookie';

// Slick Carousel
import 'slick-carousel';

import 'magnific-popup'

// AOS
import AOS from 'aos';

// Turbo SPA
import * as Turbo from "@hotwired/turbo";

Turbo.setProgressBarDelay(500);

import scrollToElement from "scroll-to-element";

import './js/header';

document.addEventListener("turbo:before-render", (event) => {

});


document.addEventListener("turbo:load", function () {
    // Runs on every page change
    AOS.init();


    if (jQuery('.gallery').length)
        jQuery('.gallery').magnificPopup({
            delegate: 'a',
            type: 'image',
            gallery: {
                enabled: true
            },
        });

    // Pricing page
    if (jQuery('.price_calculator').length)
        jQuery('.price_calculator select').change(function () {
            $(this).closest('.pricing').find('.price').text('£' + $(this).val());
        });

    // Tabs
    if (jQuery('section.tabs').length) {
        jQuery('section.tabs .tab').click(function (e) {
            if (!$(this).hasClass('active')) {
                let anchor = $(this).attr('href').substring(1);
                switchTab(anchor);
            }
            e.preventDefault();
        });
        // Tab on page load

        if (window.location.hash) {
            let anchor = window.location.hash.substring(1);
            switchTab(anchor);
        }
    }
});

document.addEventListener("turbo:click", (event) => {
    // Fixes a turbo bug where clicking the same page with an anchor that has custom javascript
    let split = event.detail.url.split("#");
    let anchor = split[1];
    if (anchor) {
        switchTab(anchor);
        scrollToElement('section.tabs #content_' + anchor, {
            offset: -60,
            duration: 300
        });
    }
});

function switchTab(anchor) {
    jQuery('section.tabs .tab').removeClass('active');
    jQuery('section.tabs .content').removeClass('active');
    jQuery('section.tabs .tab#tab_' + anchor).addClass('active');
    jQuery('section.tabs .content#content_' + anchor).addClass('active');

}
