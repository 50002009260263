import jQuery from 'jquery';

document.addEventListener("turbo:load", function () {

    // DROPDOWN MENU

    // Open sub menu when clicked
    jQuery('.menu-item-has-children > a').click(function(e) {
        e.preventDefault();
        if (jQuery(window).width() >= 960) { // MD
            jQuery('.menu-item-has-children.open').removeClass('open');
            jQuery(this).parent().addClass('open');
        } else {
            jQuery(this).parent().toggleClass('open');
        }
    });




    // MOBILE MENU

    jQuery('.burger, .menu-close').click(function(e) {
        e.preventDefault();
        jQuery('body').toggleClass('menu-open');
    });

    //

    jQuery('*').click(function(e) {
        // Close dropdown menu when losing focus
        if($(e.target).closest('.menu-item-has-children').length === 0) {
            jQuery('.menu-item-has-children.open').removeClass('open');
        }

        if($(e.target).closest('#menu, .burger').length === 0) jQuery('body').removeClass('menu-open');
    });

});